import * as faceapi from "face-api.js";
import { ref } from "vue";

export function useFaceApi() {
    let remoteFaceApi = ref(false);
    const initFaceApi = () => {

        try {
            const video = document.querySelector("#remote-media-div > video");
            const videoContainer = document.querySelector("#remote-media-div");

            // const textBox = document.createElement("input");

            // textBox.setAttribute("type", "text");
            // textBox.setAttribute("style", "coler:red");

            console.log("initFaceApi");
            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
                faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
                faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
                faceapi.nets.faceExpressionNet.loadFromUri("/models"),
            ]);

            const canvas = faceapi.createCanvasFromMedia(video);
            canvas.style =
                "z-index: 2; position: absolute; margin-top: 30px !important;";
            videoContainer.appendChild(canvas);

            const boxSize = {
                width: video.clientWidth,
                height: video.clientHeight,
            };

            faceapi.matchDimensions(canvas, boxSize);

            setInterval(async () => {
                //async
                // await
                const detections = await faceapi
                    .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                    .withFaceLandmarks()
                    .withFaceExpressions();

                canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
                const resizedDetections = faceapi.resizeResults(detections, boxSize);

                // faceapi.draw.drawDetections(canvas, resizedDetections);

                // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

                //textBox.setAttribute("value", detections[0].expressions.neutral);

                faceapi.draw.drawFaceExpressions(canvas, resizedDetections);

                // console.log(detections);
            }, 500);
            return true;
        } catch (error) {
            return false;
        }
    };
    return { initFaceApi, remoteFaceApi, faceapi };
}
