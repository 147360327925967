import { createApp } from "vue";
import App from "./App.vue";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import Password from "primevue/password";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Checkbox from "primevue/checkbox";
import ToastService from "primevue/toastservice";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Image from "primevue/image";
import Textarea from "primevue/textarea";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import RadioButton from "primevue/radiobutton";
import Slider from "primevue/slider";
import ProgressBar from "primevue/progressbar";
import SpeedDial from "primevue/speeddial";
import Rating from "primevue/rating";
import Tooltip from "primevue/tooltip";

import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";

import QuestionCard from "./components/QuestionCard.vue";
import QuestionComponent from "./components/QuestionComponent.vue";
import StarAnimation from "./components/StarAnimation.vue";
import WebRtcWidget from "./components/WebRtc.vue";

import axios from "axios";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primeflex/primeflex.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

//icons
axios.defaults.baseURL = "https://dilvekonusmaterapim.com/wp-json/";
axios.interceptors.request.use(
  (request) => {
    // console.log(request);
    // Edit request config
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    console.log(response);
    console.log(response.config.url);
    // Edit response config
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);

app.component("PrimeButton", Button);
app.component("PrimeProgressBar", ProgressBar);
app.component("PrimeInputText", InputText);
app.component("PrimeDropdown", Dropdown);
app.component("PrimeToast", Toast);
app.component("PrimePassword", Password);
app.component("PrimeCard", Card);
app.component("PrimeDialog", Dialog);
app.component("PrimeDivider", Divider);
app.component("PrimeCheckbox", Checkbox);
app.component("PrimeImage", Image);
app.component("PrimeTextarea", Textarea);
app.component("PrimeSplitter", Splitter);
app.component("PrimeSplitterPanel", SplitterPanel);
app.component("PrimeRadioButton", RadioButton);
app.component("PrimeSlider", Slider);
app.component("PrimeSpeedDial", SpeedDial);
app.component("PrimeRating", Rating);

app.directive("tooltip", Tooltip);

app.component("PacmanLoader", PacmanLoader);

app.component("QuestionCard", QuestionCard);
app.component("QuestionComponent", QuestionComponent);
app.component("StarAnimation", StarAnimation);
app.component("WebRtcWidget", WebRtcWidget);

app.mount("#app");
