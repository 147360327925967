<template>
    <div class="video">
        <div class="video__partner">
            <div v-if="!remoteStream" class="video__spinner">Loading</div>
            <video id="remoteVideo" class="video__spinner" autoplay></video>
        </div>
        <video id="localVideo" class="video__myself" autoplay></video>
    </div>
</template>
  
  
<script>
import { servers } from "./../utils/ICEServers";
import { log } from "./../utils/logging";
import mqtt from "mqtt";

export default {
    name: "WebRtc",
    props: {
        appointmentId: { type: String },

    },
    data: () => ({
        // videos
        room: "37-patient-vue-client",
        to: "37-patient",
        myVideo: {},
        videoAnswer: {
            video: undefined,
            remoteDesc: undefined,
            candidate: undefined,
            close: false
        },
        remoteVideo: {},
        socket: null,

        // Media config
        constraints: {
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: false
            },
            video: {
                width: 450,
                height: 348
            }
        },

        // local & remote video stream
        localStream: undefined,
        remoteStream: undefined,

        // STUN ice servers
        configuration: servers,

        // Peer connection
        pc: undefined,

        // Offer config
        offerOptions: {
            offerToReceiveAudio: 1,
            offerToReceiveVideo: 1
        },

        username: "vue-client"
    }),
    async created() {
        this.username = "vue-client";


        // Create peer connection
        this.createPeerConnection();
        // Add local stream

        // Event listeners
        this.onIceCandidates();
        this.onAddStream();

        !this.videoAnswer.video ?
            this.callFriend() : // Caller
            this.handleAnser() // Callee
        // this.callFriend()



    },
    mounted() {
        this.createConnection()
        this.myVideo = document.getElementById("localVideo");
        this.remoteVideo = document.getElementById("remoteVideo");
    },
    methods: {

        doPublish(message) {

            this.socket.publish(this.appointmentId, message, 0, (error) => {
                if (error) {
                    console.log("Publish error", error);
                }
            });
        },

        createConnection() {

            const connectUrl = `wss://konusmaterapim.com:8084/mqtt`;

            try {
                this.socket = mqtt.connect(connectUrl, {
                    clientId: "vue-client",
                });
                this.socket.on("connect", () => {
                    // console.log("Connection succeeded!");
                });
                this.socket.on("error", (error) => {
                    console.log("Connection failed", error);
                });
                this.socket.on("message", (topic, message) => {
                    this.mqttMessageController(message);
                });

                this.doSubscribe(this.socket);

                return this.socket;
            } catch (error) {
                console.log("mqtt.connect error", error);
            }
        },


        mqttMessageController(message) {
            try {
                const comingJson = JSON.parse(message);
                if (comingJson.data.from !== this.username) {
                    if (comingJson.type == "answer") {
                        this.videoAnswer = { ...this.videoAnswer, remoteDesc: comingJson };

                    }
                    else if (comingJson.type == "candidate") {
                        let candNew = {
                            candidate: {

                                candidate: comingJson.data.candidate.candidate,
                                sdpMid: comingJson.data.candidate.sdpMid,
                                sdpMLineIndex: comingJson.data.candidate.sdpMLineIndex,
                                usernameFragment: "fu7l"
                            },
                            to: comingJson.data.to,
                            from: comingJson.data.from,
                            session_id: comingJson.data.session_id
                        }
                        this.videoAnswer = { ...this.videoAnswer, ...candNew };
                    }
                    else if (comingJson.type == "offer") {

                        let descNew = {
                            desc: comingJson.data.description,
                            to: comingJson.data.to,
                            from: comingJson.data.from,
                            room: comingJson.data.session_id
                        }
                        this.videoAnswer = {
                            ...this.videoAnser,
                            video: true,
                            remoteDesc: descNew,
                            from: comingJson.data["from"]
                        };


                    }
                }
                console.log(comingJson);
            } catch (error) {
                console.log(error);
                console.log(message);
            }


        },


        doSubscribe() {
            this.socket.subscribe(this.appointmentId, 0, (error, res) => {
                if (error) {
                    console.log("Subscribe to topics error", error);
                    return;
                }
                console.log("Subscribe to topics res", res);
            });
        },


        callFriend() {
            log(`${this.username} wants to start a call`);

            this.createOffer();
        },
        async handleAnser() {
            // log(`${this.username} gets an offer from ${this.videoAnswer.from}`);

            await this.setRemoteDescription(this.videoAnswer.remoteDesc);
            this.createAnswer();
        },




        createPeerConnection() {
            this.pc = new RTCPeerConnection(this.configuration);
            log(`Created ${this.username} peer connection object`);
        },

        async createOffer() {
            log(`${this.username} create an offer: start`);
            try {
                const offer = await this.pc.createOffer(this.offerOptions);
                log(`Offer from ${this.username}\n ${offer.sdp}`);

                log(`${this.username} setLocalDescription: start`);
                await this.pc.setLocalDescription(offer);
                log(`${this.username} setLocalDescription: finished`);

                this.sendSignalingMessage(this.pc.localDescription, true);
            } catch (error) {
                log(`Error creating the offer from ${this.username}. Error: ${error}`);
            }
        },

        async createAnswer() {
            log(`${this.username} create an answer: start`);
            try {
                const answer = await this.pc.createAnswer();
                log(`Answer from ${this.username}\n ${answer.sdp}`);

                log(`${this.username} setLocalDescription: start`);
                await this.pc.setLocalDescription(answer);
                log(`${this.username} setLocalDescription: finished`);

                this.sendSignalingMessage(this.pc.localDescription, false);
            } catch (error) {
                log(`Error creating the answer from ${this.username}. Error: ${error}`);
            }
        },

        sendSignalingMessage(desc, offer) {
            const isOffer = offer ? "offer" : "answer";
            log(`${this.username} sends the ${isOffer} through the signal channel`);

            // send the offer to the other peer


            const mess = JSON.stringify({
                type: desc.type,
                data: {
                    to: this.to,
                    from: "vue-client",
                    description: desc,
                    session_id: this.room,
                    media: "video"
                }

            })
            this.doPublish(mess)

        },

        async setRemoteDescription(remoteDesc) {
            try {
                log(`${this.username} setRemoteDescription: start`);

                // await this.pc.setRemoteDescription(remoteDesc);
                console.log("remoteDesc=>>>>>>>>>>>>>>>>>>>>>>>>" + remoteDesc);
                // remoteDesc.desc.data.description += "\n";
                await this.pc.setRemoteDescription(remoteDesc.data.description);
                log(`${this.username} setRemoteDescription: finished`);
            } catch (error) {
                log(`Error setting the RemoteDescription in ${this.username}. Error: ${error}`
                );
            }
        },

        onIceCandidates() {
            // send any ice candidates to the other peer
            this.pc.onicecandidate = ({ candidate }) => {

                const mess = JSON.stringify({
                    type: "candidate",
                    data: {
                        to: this.to,
                        from: "vue-client",
                        candidate,
                        session_id: this.room
                    }

                })

                this.doPublish(mess)
                // this.$socket.emit("privateMessagePCSignaling", {
                //     candidate,
                //     to: this.to,
                //     from: "vue-client",
                //     room: this.room
                // });
            };
        },

        async addCandidate(candidate) {
            try {
                log(`${this.username} added a candidate`);
                await this.pc.addIceCandidate(candidate);
                log(`Candidate added`);
            } catch (error) {
                log(`Error adding a candidate in ${this.username}. Error: ${error}`)
            }
        },

        onAddStream() {
            this.pc.onaddstream = (event) => {
                if (event.stream) {
                    this.remoteStream = event.stream
                    this.remoteVideo.srcObject = this.remoteStream;
                }
            }
        },



        resetConnection() {
            this.pc.close();
            this.pc = null;
            this.localStream.getTracks().forEach(track => track.stop())
            // this.$emit("closeVideo");
        }
    },

    watch: {
        videoAnswer: function (newVal, oldVal) {
            const desc = newVal.remoteDesc;
            const candidate = newVal.candidate;
            const close = newVal.close;
            if (desc !== undefined && desc !== oldVal.remoteDesc) {
                this.setRemoteDescription(desc);
            }
            if (candidate !== undefined && candidate !== oldVal.candidate) {
                this.addCandidate(candidate);
            }
            if (close && close !== oldVal.close) {
                this.resetConnection();
            }

        }
    }

};
</script>
  
<style lang="scss" scoped>
.video {
    background-color: grey;
    position: relative;
    height: 100%;

    &__partner {
        height: 100%;
    }

    &__myself {
        bottom: 0;
        position: absolute;
        float: right;
        width: 130px;
        right: 0;
        height: 100px;
        z-index: 2;
    }

    &__spinner {
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
  
  