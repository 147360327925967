import axios from "axios";


const setAnswer = async (answer) => {
    try {
        const response = await axios.post(`kivicare/api/v1/sheetanswer/add-sheetanswer`, {
            ...answer
        });

        if (response.status == 200) {
            return response.data
        }
        return null

    } catch (e) {
        return null //UserNotFound
    }

};

export default {
    setAnswer,


};
