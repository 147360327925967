<template>
  <PrimeToast />
  <div v-if="loading" :style="{
    display: 'flex',
    justifyContent: 'center',
    padding: '60px',
  }">
    <PacmanLoader />
  </div>

  <div v-else :style="{
    width: '100%',
    height: '100%',
  }">
    <!-- <WebRtc></WebRtc> -->
    <SigninScreen v-if="!isLogin" @login-event="login" :apiService="apiService" :getLoader="getLoader"
      :urlLoginName="urlLoginName" />
    <MeetComponent v-else :user="user" :appointment="appointment" :allmeetingGames="allmeetingGames"
      :allvoiceTherapistSheets="allvoiceTherapistSheets" :allSheetQuestions="allSheetQuestions" />
  </div>
</template>

<script>
import appointmentService from "./service/http/appointment.service";
import sheetQuestionService from "./service/http/sheet.question.service";
import voiceTherapistSheetService from "./service/http/voice.therapist.sheet.service";
import meetingGameService from "./service/http/meeting.game.service";
import SigninScreen from "./components/SigninScreen.vue";
// import WebRtc from "./components/WebRtc.vue";
import MeetComponent from "./components/MeetComponent.vue";
import axios from "axios";
import usersService from "./service/http/users.service";
import { isSupported } from "twilio-video";

export default {
  components: {
    // WebRtc,
    SigninScreen,
    MeetComponent,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("userlink");
    return {
      appointment: null,
      userName: "Vue.js",
      registrantToken: "",
      apiKey: "BYY9iwTXSYS2lweZg_DZhg",
      apiSecret: "7ZUE0iP9IDVcgDbTc3waPfW2V4icD3Up83m3",
      leaveUrl: "http://www.dilvekonusmaterapim.com/",
      meetingNumber: "",
      passWord: "",
      role: 0,
      user: null,
      loading: true,
      isLogin: false,
      urlLoginName: myParam,
      signature: "",
    };
  },

  async mounted() {
    if (isSupported) {
      const VueThis = this;
      window.onbeforeunload = function () {
        VueThis.leaveRoomIfJoined();
      };
      var tokenData = localStorage.getItem("user_token");
      if (tokenData) {
        this.apiService();
      } else {
        this.loading = false;
      }
    } else {
      this.$toast.add({
        severity: "warn",
        summary: "Tarayıcı Hatası",
        detail:
          "Tarayıcınız Sistemi Desteklemiyor. Lütfen Chrome Veya Microsoft Edge Kullanınız",
        life: 2000,
      });
    }
  },

  methods: {
    updateTitleText(title) {
      console.log(this.shareScreen);
      console.log(title);
      this.shareScreen = title;
      console.log(this.shareScreen);
    },
    login(user) {
      this.user = user;
    },
    getLoader() {
      this.loading = true;
    },
    async apiService() {
      try {
        console.log(this.user);
        let tokenData = localStorage.getItem("user_token");
        let userId = localStorage.getItem("user_id");
        if (tokenData) {
          axios.defaults.headers.common["Authorization"] = "Bearer " + tokenData;
          const user = await usersService.getUserDetail(userId);
          user.token = tokenData;
          this.user = user;

          if (user && user.role) {
            this.role = user.role == "patient" ? 0 : 1;
            const appointments = await this.getAppointment();
            if (appointments) {
              const current = Date.now();
              const startedMeet = appointments.data.find((element) => {

                const startTime = new Date(`${element.appointment_start_date} ${element.appointment_start_time}`);

                startTime.setMinutes(startTime.getMinutes() - 10);

                const endTime = new Date(`${element.appointment_end_date} ${element.appointment_end_time}`);

                console.log(startTime, endTime, current, appointments);
                console.log(`startTime:${startTime.getTime()} endTime:${endTime.getTime()} current:${current}`);
                console.log(startTime.getTime() <= current && endTime.getTime() >= current);
                return startTime.getTime() <= current && endTime.getTime() >= current;
              });
              if (startedMeet == undefined) {
                this.appointment = appointments.data[9];
                this.isLogin = true;
                this.loading = false;
                return;
                // this.clearUser("Aktif Bir Randevunuz Bulunmamaktadır", "Lütfen Terapistinizle İletişime Geçiniz")
              }
              console.log(startedMeet);
              this.appointment = startedMeet;
              if (user.role === "doctor") {
                this.allmeetingGames = await meetingGameService.getAll();
                this.allvoiceTherapistSheets =
                  await voiceTherapistSheetService.getAll();
                this.allSheetQuestions = await sheetQuestionService.getAll();
              }
              this.isLogin = true;
              this.loading = false;
            }
          } else {
            this.clearUser("Yanlış Veya Son Kullanımı Süresi Bitmiş Anahtar", "Lütfen Tekrar Giriş Yapınız")
          }
        } else {
          this.clearUser("Yanlış Veya Son Kullanımı Süresi Bitmiş Anahtar", "Lütfen Tekrar Giriş Yapınız")

        }
      } catch (error) {
        console.log(error);
        this.clearUser("Yanlış Veya Son Kullanımı Süresi Bitmiş Anahtar", "Lütfen Tekrar Giriş Yapınız")
      }
    },

    clearUser(summary, detail) {
      console.log("clean storage");
      axios.defaults.headers.common["Authorization"] = '';
      localStorage.removeItem("user_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");

      this.loading = false;
      this.$toast.add({
        severity: "warn",
        summary: summary,
        detail:
          detail,
        life: 4000,
      });
    },

    getUserDetail: async (id) => {
      return usersService.getUserDetail(id);
    },

    getAppointment: async () => {
      return appointmentService.getAppointment();
    },
  },

};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

html,
body {
  min-width: 0px !important;
  background-color: #202124;
}
</style>
