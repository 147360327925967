<template>
  <link v-if="startGame" rel="stylesheet" href="../css/meetStyle.css" />
  <link v-if="startBoard" rel="stylesheet" href="../css/meetStyle.css" />
  <div class="row margin-zero" style="height: 90%">
    <PrimeToast />
    <PrimeDialog :closable="false" :visible="mediaControllerModal" position="top"
      :style="{ width: '40vw', textAlign: center }" :breakpoints="{ '960px': '80vw' }">
      <template #header>
        <div class="container" style="margin-top: 15px; margin-bottom: 15px">
          <div class="row">
            <div class="col-md-8">
              <h3>Kamera Mikrofon Ayarları</h3>
            </div>
            <div class="col-md-2 offset-md-2">
              <PrimeButton icon="pi pi-times" @click="mediaController" autofocus />
            </div>
          </div>
        </div>
      </template>
      <PrimeCard style="margin-bottom: 2em">
        <template #title> Kamera Seçimi </template>
        <template #content>
          <div class="row text-center">
            <div class="col-md-4 col-sm-4 col">
              <PrimeDropdown v-model="selectedCameraDevice" :options="cameraDevices" optionLabel="label"
                optionValue="deviceId" placeholder="Kamera Seçiniz" />
            </div>
            <div class="col-md-4 col-sm-4 col">
              <div class="box" id="box3" style="width: 50%">
                <video style="width: 100%" autoPlay playsInline ref="videoElementRef" />
              </div>
            </div>
          </div>
        </template>
      </PrimeCard>
      <PrimeCard style="margin-bottom: 2em">
        <template #title> Mikrofon Seçimi </template>
        <template #content>
          <div class="row text-center">
            <div class="col-md-4 col-sm-4 col">
              <PrimeDropdown v-model="selectedAudioInputDevice" :options="audioInputDevices" optionLabel="label"
                optionValue="deviceId" placeholder="Mikrofon Seçiniz" />
            </div>
            <div class="col-md-4 col-sm-4 col">
              <PrimeProgressBar :value="microfoneVolumeLevel" :showValue="false" />
            </div>
          </div>
        </template>
      </PrimeCard>
      <template #footer>
        <PrimeButton label="Seansa Başla" icon="pi pi-check" @click="startSeans" autofocus />
      </template>
    </PrimeDialog>

    <div class="box" id="box1" style="width: 25%; height: 90vh; float: left">
      <div class="row margin-zero" id="remote_screen_video_container">

        <div id="remote-screen-media-div" @click="videoLocationController($event)">
          <WebRtcWidget :appointmentId="appointment.id.toString()" />
        </div>
      </div>
    </div>
    <div class="box" id="box2" style="width: 50%; height: 90vh; float: left">
      <div class="spacing"></div>
      <div class="row margin-zero" id="remote_video_container">
        <div id="remote-media-div" @click="videoLocationController($event)"></div>
      </div>
      <div class="row margin-zero">
        <div id="meeting-info-container"></div>
      </div>
    </div>
    <div class="box" id="box3" style="width: 25%; height: 90vh; float: left">
      <div id="local-media"></div>
      <PrimeButton v-if="user.role === 'doctor'" icon="fa-solid fa-star" label="Danışana Yıldız Gönder"
        @click="giveStarForPatinent" />
    </div>
    <div v-if="user.role === 'patient'" id="gameFrameDiv" :style="{
      background: '#202124',
      height: '0vh',
      width: '75%',
      float: 'left',
    }">
      <PrimeDialog contentClass="patientQuestionCard" :visible="showPatientModal" :closable="false" position="top"
        :style="{ textAlign: center }">
        <!-- :breakpoints="{ '960px': '80vw' }" -->
        <!-- width: '70vw', -->
        <QuestionCard :questions="questions" />
      </PrimeDialog>

      <PrimeDialog contentClass="starCardBg" :closable="false" position="bottomleft" :visible="true">
        <label v-for="index in parseInt(gameStars)" :key="index">
          <StarAnimation />
        </label>
      </PrimeDialog>

      <iframe id="gameFrame" allow="fullscreen;camera;microphone" width="100%" height="100%" marginwidth="0"
        marginheight="0" hspace="0" vspace="0" frameborder="0" scrolling="auto" webkitallowfullscreen="true"
        mozallowfullscreen="true" msallowfullscreen="true" title="Navigation menu" />
    </div>

    <div id="therapist-frame-container" v-else>
      <div id="therapist-frame" :style="{
        background: '#202124',
        height: '0vh',
        width: '100%',
        float: 'left',
      }">
        <iframe id="gameFrame" allow="fullscreen;camera;microphone" width="100%" height="100%" marginwidth="0"
          marginheight="0" hspace="0" vspace="0" frameborder="0" scrolling="auto" webkitallowfullscreen="true"
          mozallowfullscreen="true" msallowfullscreen="true" title="Navigation menu" />
      </div>
      <PrimeDialog :closable="false" :visible="showTherapistTools" :style="{ width: '40vw', textAlign: center }"
        :breakpoints="{ '960px': '80vw' }">
        <template #header>
          <div class="container" style="margin-top: 15px; margin-bottom: 15px">
            <div class="row">
              <div class="col-md-5">
                <h3>Seans Araçları</h3>
              </div>
              <div class="col-md-1 offset-md-6">
                <PrimeButton icon="pi pi-times" @click="closeTherapistToolsModal" autofocus />
              </div>
            </div>
          </div>
        </template>
        <div class="container-fluid bd-row" style="margin-top: 15px">
          <PrimeCard style="margin-bottom: 2em">
            <template #title> Oyun Kontrolleri </template>
            <template #content>
              <div class="row text-center">
                <div class="col-md-4 col-sm-4 col">
                  <PrimeButton label="Oyunu Başlat" class="p-button-success" @click="publishStartGame" />
                </div>
                <div class="col-md-4 col-sm-4 col">
                  <PrimeButton label="Oyunu Beklet" class="p-button-warning" @click="publishPauseGame" />
                </div>
                <div class="col-md-4 col-sm-4 col">
                  <PrimeButton label="Oyunu Durdur" class="p-button-danger" @click="publishStopGame" />
                </div>
              </div>
            </template>
          </PrimeCard>
          <PrimeCard style="margin-bottom: 2em">
            <template #title> Oyun Değiştirme </template>
            <template #content>
              <div class="row text-center">
                <div class="col-md-6 col-sm-6 col">
                  <PrimeDropdown v-model="selectedGame" :options="allmeetingGames" option-label="game" :filter="true"
                    placeholder="Oyun Seçiniz" :show-clear="true">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="country-item country-item-value">
                        <div>
                          <!-- <PrimeImage :src="slotProps.value.game_pic" /> -->
                          {{ slotProps.value.game }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          <!-- <PrimeImage :src="slotProps.value.game_pic" /> -->
                          {{ slotProps.option.game }}
                        </div>
                      </div>
                    </template>
                  </PrimeDropdown>
                </div>
                <div class="col-md-6 col-sm-6 col">
                  <PrimeButton label="Yeni Oyunu Başlat" class="p-button-warning" @click="publishGame" />
                </div>
              </div>
            </template>
          </PrimeCard>
          <PrimeCard style="margin-bottom: 2em">
            <template #title>Çizim Tahtası</template>
            <template #content>
              <div class="row text-center">
                <div class="col-md-6 col-sm-6 col">
                  <PrimeButton label="Aç" class="p-button-success" @click="openBoardRoom" />
                </div>
                <div class="col-md-6 col-sm-6 col">
                  <PrimeButton label="Kapat" class="p-button-warning" @click="closeBoardRoom" />
                </div>
              </div>
            </template>
          </PrimeCard>

          <PrimeCard style="margin-bottom: 2em">
            <template #title> Çalışma Ve Sorular </template>
            <template #content>
              <div class="row text-center" style="margin-bottom: 1em">
                <div class="col-md-6 col-sm-6 col">
                  <PrimeDropdown v-model="selectedVoiceTherapistSheet" :options="allvoiceTherapistSheets"
                    option-label="sheet_name" :filter="true" placeholder="Çalışma Seçiniz" :show-clear="true"
                    @change="getSheetQuestionBySheetId">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="country-item country-item-value">
                        <div>
                          {{ slotProps.value.sheet_name }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          {{ slotProps.option.sheet_name }}
                        </div>
                      </div>
                    </template>
                  </PrimeDropdown>
                </div>
                <div class="col-md-6 col-sm-6 col">
                  <PrimeDropdown v-model="selectedSheetQuestion" :options="sheetQuestion" option-label="word"
                    :filter="true" placeholder="Soru Seçiniz" :show-clear="true">
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="country-item country-item-value">
                        <div>
                          {{ slotProps.value.word }}
                        </div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>
                          {{ slotProps.option.word }}
                        </div>
                      </div>
                    </template>
                  </PrimeDropdown>
                </div>
              </div>

              <div class="row text-center">
                <div class="col-md-6 col-sm-6 col">
                  <PrimeButton label="Soru Gönder" class="p-button-success" @click="publishQuesion" />
                </div>
                <div class="col-md-6 col-sm-6 col">
                  <PrimeButton label="Soruları Sıfırla" class="p-button-warning" @click="resetQuesion" />
                </div>
              </div>
            </template>
          </PrimeCard>
          <PrimeCard style="margin-bottom: 2em">
            <template #title>Cevap Kontrolleri</template>
            <template #content>
              <div class="row" style="margin-bottom: 1em">
                <div class="col-md-12">
                  <QuestionCard :questions="questions" />
                </div>
              </div>

              <div class="row text-center">
                <div class="col-md-6 col-sm-6 col">
                  <PrimeButton label="Doğru" class="p-button-success" @click="applyAnswer" />
                </div>
                <div class="col-md-6 col-sm-6 col">
                  <PrimeButton label="Yanlış" class="p-button-warning" @click="rejectAnswer" />
                </div>
              </div>
            </template>
          </PrimeCard>
          <PrimeCard style="margin-bottom: 2em">
            <template #title>Cümle Gönderimi</template>
            <template #content>
              <div class="row text-center" style="margin-bottom: 1em">
                <div class="col-md-6 col-sm-6 col">
                  <PrimeTextarea :autoResize="true" rows="5" cols="30" v-model="therapistText" />
                </div>
                <div class="row text-center">
                  <div class="col-md-6 col-sm-6 col">
                    <PrimeButton label="Cümle Gönder" class="p-button-success" @click="publishText" />
                  </div>
                  <div class="col-md-6 col-sm-6 col">
                    <PrimeButton label="Cümle Sıfırla" class="p-button-warning" @click="resetQuesion" />
                  </div>
                </div>
              </div>
            </template>
          </PrimeCard>

          <PrimeCard style="margin-bottom: 2em">
            <template #title>Danışan Oyun Ayarı</template>
            <template #content>
              <div class="row text-center">
                <div class="col-md-6 col-sm-6 col">
                  <h5>Oyun Genişliği</h5>
                  <PrimeInputText v-model.number="gameWidth" />
                  <PrimeSlider v-model="gameWidth" @change="pusblishSettingMessage" />
                </div>
                <div class="col-md-6 col-sm-6 col text-center">
                  <h5>Çalışma Arka Planı</h5>
                  <div :style="{ marginLeft: '30%' }">
                    <div class="field-radiobutton">
                      <PrimeRadioButton @change="pusblishSettingMessage" id="cardColor0" name="QuestionCardColor"
                        value="white" v-model="QuestionCardColor" />
                      <label for="cardColor0">Beyaz</label>
                    </div>
                    <div class="field-radiobutton">
                      <PrimeRadioButton @change="pusblishSettingMessage" id="cardColor1" name="QuestionCardColor"
                        value="transparent" v-model="QuestionCardColor" />
                      <label for="cardColor1">Transparan</label>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </PrimeCard>
        </div>
      </PrimeDialog>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-md-center margin-zero" id="meet-button-container">
      <div class="meet-button col-md-2">
        <div class="speeddial-circle-demo">
          <PrimeSpeedDial :model="myItems" :radius="120" direction="up-left" type="quarter-circle" :transitionDelay="80"
            :showIcon="[
              user.role === 'doctor'
                ? 'fa-solid fa-user-doctor'
                : 'fa-solid fa-child',
            ]" hideIcon="pi pi-times" buttonClass="p-button-primary p-button-rounded
                                                                        p-button-primary"
            :tooltipOptions="{ position: 'top', event: 'hover' }" } />
        </div>
      </div>
      <div id="meet-container" class="meet-button col-md-2">
        <PrimeButton id="meet-tools" icon="fa-solid fa-wrench" label="Seans Araçları"
          class="p-button-rounded p-button-warning" />
      </div>
      <div class="meet-button col-md-2">
        <PrimeButton @click="mediaController" icon="pi pi-briefcase" label="Medya Yöneticisi"
          class="p-button-primary p-button-rounded p-button-primary" />
      </div>

      <div class="meet-button col-md-2">
        <PrimeButton icon="fa-solid fa-right-from-bracket" label="Seansı Bitir" class="p-button-rounded p-button-danger"
          @click="leaveMeet" />
      </div>
      <div v-if="user.role === 'doctor'" class="meet-button col-md-2" :style="{ paddingRight: '8%' }">
        <div class="speeddial-circle-demo">
          <PrimeSpeedDial :model="remoteItems" :radius="120" direction="up-right" type="quarter-circle"
            :transitionDelay="80" showIcon="fa-solid fa-child" hideIcon="pi pi-times"
            buttonClass="p-button-primary p-button-rounded   p-button-primary"
            :tooltipOptions="{ position: 'top', event: 'hover' }" } />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import sheetAnswerService from "../service/http/sheet.answer.service";
import sheetQuestionService from "../service/http/sheet.question.service";
import sheetAnswerService from "../service/http/sheet.answer.service";
// import { LocalVideoTrack } from "twilio-video";
import {
  //createLocalVideoTrack,
  LocalVideoTrack,
  connect,
  createLocalTracks,
} from "twilio-video";
//import { VideoRoomMonitor } from "@twilio/video-room-monitor";
import {
  testVideoInputDevice,
  VideoInputTest,
  testAudioInputDevice,
  AudioInputTest,
} from "@twilio/rtc-diagnostics";
import { useMqtt } from "../service/mqtt/mqtt.base";
import { useFaceApi } from "../ml/faceDetection.js";
import axios from "axios";

export default {
  props: {
    user: { type: Object },
    appointment: { type: Object },
    allmeetingGames: { type: Object },
    allvoiceTherapistSheets: { type: Object },
    allSheetQuestions: { type: Object },
  },

  setup() {
    const {
      camera,
      microfone,
      publish,
      questions,
      startBoard,
      startGame,
      client,
      screenSize,
      shareScreen,
      videoLocation,
      remoteShareScreen,
      remoteVideoLocation,
      remoteScreenSize,
      gameStars,
      showPatientModal,
      createConnection,
      doPublish,
    } = useMqtt();

    const { initFaceApi, remoteFaceApi, faceapi } = useFaceApi();

    return {
      camera,
      microfone,
      screenSize,
      shareScreen,
      videoLocation,
      publish,
      questions,
      client,
      startGame,
      startBoard,
      remoteShareScreen,
      remoteVideoLocation,
      remoteScreenSize,
      gameStars,
      showPatientModal,
      createConnection,
      doPublish,
      initFaceApi,
      remoteFaceApi,
      faceapi
    };
  },

  data() {
    return {
      myItems: [
        {
          label: "Kamera",
          icon: "pi pi-camera text-primary",

          command: () => {
            this.cameraController();
          },
        },
        {
          label: "Mikrofon",
          icon: "fa-solid fa-microphone text-primary",
          command: () => {
            this.microphoneController();
          },
        },
        {
          label: "Ekran Paylaşımı",
          icon: "pi pi-external-link text-danger",
          command: async () => {
            this.shareScreenController();
          },
        },
        {
          label: "Tam Ekran",
          icon: "fa-solid fa-maximize text-danger",
          command: async () => {
            this.screenSizeController();
          },
        },
        {
          label: "Video Yerini Değiştir",
          icon: "fa-solid fa-camera-rotate text-primary",
          command: async () => {
            this.videoLocationController();
          },
        },
      ],
      remoteItems: [
        {
          label: "Danışan Ekran Paylaşımı",
          icon: "pi pi-external-link text-danger",
          command: async () => {
            this.remoteShareScreenController();
          },
        },

        {
          label: "Danışan Video Yeri",
          icon: "fa-solid fa-camera-rotate text-primary",
          command: async () => {
            this.remoteVideoLocationController();
          },
        },
        {
          label: "Danışan Tam Ekran",
          icon: "fa-solid fa-maximize text-danger",
          command: async () => {
            this.remoteScreenSizeController();
          },
        },
        {
          label: "Danışan Duygu Analizi",
          icon: "fa-solid fa-heart-pulse text-danger",
          command: async () => {
            this.faceApiController();

          },
        },
      ],
      selectedVoiceTherapistSheet: null,
      selectedSheetQuestion: null,
      selectedGame: null,
      therapistText: "",
      answer: {},
      gamePercent: "40%",
      QuestionCardColor: "white",
      gameWidth: 75,
      mediaControllerModal: true,
      // BOARD DEĞİŞİKLİK
      boardUrl:
        "https://stream.dilvekonusmaterapim.com/demos/canvas-designer/index.html#",
      selectedCameraDevice: null,
      selectedAudioInputDevice: null,
      audioInputDevices: [],
      cameraDevices: [],
      stream: null,
      videoInputDeviceTestOld: null,
      audioInputDeviceTestOld: null,
      showTherapistTools: false,
    };
  },

  mounted() {
    this.init();
  },
  watch: {
    gameStars(value) {
      if (value === 6) {
        this.gameStars = 0;
      }
    },

    selectedCameraDevice(newDevice) {
      this.testCamera(this.$refs.videoElementRef, newDevice);
    },
    selectedAudioInputDevice(newDevice) {
      this.testAudioInput(newDevice);
    },
    remoteCamera(value) {
      if (this.isRemoteListener()) {
        console.log(`remoteCamera ${value}`);
        this.cameraController();
      }
    },
    remoteMicrofone(value) {
      if (this.isRemoteListener()) {
        console.log(`remoteMicrofone ${value}`);
        this.microfoneController();
      }
    },
    remoteScreenSize(value) {
      if (this.isRemoteListener()) {
        console.log(`remoteScreenSize ${value}`);
        this.screenSizeController();
      }
    },
    remoteShareScreen(value) {
      console.log(`remoteShareScreen ${value}`);
      if (this.isRemoteListener()) {
        this.shareScreenController();
      }
    },
    remoteVideoLocation(value) {
      console.log(`remoteShareScreen ${value}`);
      if (this.isRemoteListener()) {
        this.videoLocationController();
      }
    },
  },

  methods: {
    async init() {

      try {
        this.client = this.createConnection(
          this.client,
          this.user,
          this.appointment
        );

      } catch (error) {

        this.$toast.add({
          severity: "warn",
          summary: "Anlık Bağlantı Hatası",
          detail:
            "Lütfen Destek Ekibiyle iletişime Geçiniz.",
        });

      }
      try {
        if (this.user.role === "doctor") {
          document.getElementById("meet-container").style.display = "inline";
          var button = document.getElementById("meet-tools");
          button.addEventListener("click", () => {
            this.showTherapistTools = true;
          });
          button.style.display = "inline-flex";
        } else if (this.user.role === "patient") {
          this.stream = await navigator.mediaDevices.getDisplayMedia({
            video: { frameRate: 15 },
          });
        }
        const devices = await navigator.mediaDevices.enumerateDevices();

        devices.forEach((element) => {
          if (element.kind == "videoinput") {
            this.cameraDevices.push({
              label: element.label,
              deviceId: element.deviceId,
            });
          }
        });

        devices.forEach((element) => {
          if (element.kind == "audioinput") {
            this.audioInputDevices.push({
              label: element.label,
              deviceId: element.deviceId,
            });
          }
        });
        console.log(this.appointment);


      } catch (error) {
        this.$toast.add({
          severity: "warn",
          summary: "Kamera veya Mikrofon Hatası",
          detail:
            "Lütfen tarayıcınızı yeniden başlatın ve mikrofon , kamera erişimine izin verin.",
        });
      }
    },

    /////////////////////////////////////////
    leaveRoomIfJoined() {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }
    },
    isRemoteListener() {
      if (this.user.role === "patient") {
        return true;
      }
    },
    async getAccessToken() {
      const username = this.user.first_name + "" + this.user.last_name;
      return await axios.get(
        `https://twapi.dilvekonusmaterapim.com/token?identity=${username}`
      );
    },
    async startMeeting(cameraId, microfoneId) {
      const VueThis = this;

      const data = await this.getAccessToken();

      VueThis.roomName = null;
      const token = data.data.token;

      this.leaveRoomIfJoined();

      document.getElementById("remote-media-div").innerHTML = "";
      // document.getElementById("remote-screen-media-div").innerHTML = "";

      const localTracks = await createLocalTracks({
        audio: { deviceId: microfoneId },
        video: { deviceId: cameraId },
      });

      const room = await connect(token, {
        name: VueThis.appointment.id,
        tracks: localTracks,
        // tracks: [screenTrack],
      });

      //kullanım datası görüntüleme

      // VideoRoomMonitor.registerVideoRoom(room);
      // VideoRoomMonitor.openMonitor();

      // set active toom
      VueThis.activeRoom = room;
      VueThis.roomName = "room_name";

      room.participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.track) {
            if (publication.trackName === "myscreenshare") {
              document
                .getElementById("remote-screen-media-div")
                .appendChild(publication.track.attach());
            } else {
              document
                .getElementById("remote-media-div")
                .appendChild(publication.track.attach());

            }
          }
        });

        this.trackConnections(participant);
      });

      room.on("participantConnected", (participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            const track = publication.track;
            if (publication.trackName === "myscreenshare") {
              document
                .getElementById("remote-screen-media-div")
                .appendChild(track.attach());
            } else {
              document
                .getElementById("remote-media-div")
                .appendChild(track.attach());


            }
          }
        });

        this.trackConnections(participant);
      });

      room.on("disconnected", () => {
        // Detach the local media elements
        room.localParticipant.tracks.forEach((publication) => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
        });
      });

      room.on("participantDisconnected", () => {
        document.getElementById("remote-media-div").innerHTML = "";
        document.getElementById("remote-screen-media-div").innerHTML = "";
      });

      room.localParticipant.videoTracks.forEach((publication) => {
        const localMediaContainer = document.getElementById("local-media");
        localMediaContainer.appendChild(publication.track.attach());
      });
    },
    trackConnections(participant) {
      participant.on("trackSubscribed", (track, publication) => {
        if (publication.trackName === "myscreenshare") {
          document
            .getElementById("remote-screen-media-div")
            .appendChild(track.attach());
        } else if (publication.trackName !== undefined && publication.kind === "video") {
          document
            .getElementById("remote-media-div")
            .appendChild(track.attach());

        }
      });
      participant.on("trackUnsubscribed", (track) => {
        console.log(track);
        if (track.name === "myscreenshare") {
          document.getElementById("remote-screen-media-div").innerHTML = "";
        } else if (track.name !== undefined) {
          document.getElementById("remote-media-div").innerHTML = "";
        }
      });
    },

    // changeVideoLocation() {
    //   if (this.videoLocationIsChanged) {
    //     this.myItems[4].icon = "fa-solid fa-camera-rotate text-primary";
    //     //asd this.videoLocationIsChanged = false;
    //   } else {
    //     this.myItems[4].icon = "fa-solid fa-camera-rotate text-warning";
    //     //asd this.videoLocationIsChanged = true;
    //   }
    //   const message = `{"changeVideoLocation":"${true}"}`;
    //   this.pushMqttMessage(message);
    // },

    testCamera(videoElement, deviceId) {
      if (this.videoInputDeviceTestOld !== null) {
        this.videoInputDeviceTestOld.stop();
      }

      const videoInputDeviceTest = testVideoInputDevice({
        element: videoElement,
        deviceId,
      });

      this.videoInputDeviceTestOld = videoInputDeviceTest;
      videoInputDeviceTest.on(VideoInputTest.Events.Error, (error) => {
        console.error(error);
      });

      videoInputDeviceTest.on(VideoInputTest.Events.End, (report) => {
        console.log(report);
      });
    },

    testAudioInput(deviceId) {
      if (this.audioInputDeviceTestOld !== null) {
        this.audioInputDeviceTestOld.stop();
      }
      const audioInputDeviceTest = testAudioInputDevice({
        deviceId: deviceId,
      });

      this.audioInputDeviceTestOld = audioInputDeviceTest;

      audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
        this.microfoneVolumeLevel = Math.floor(volume);
      });

      audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
        console.error(error);
      });

      audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
        console.log(report);
      });
    },

    ////////////////////////////////////

    pusblishSettingMessage() {
      const message = `{"gameWidth":"${this.gameWidth}","QuestionCardColor":"${this.QuestionCardColor
        }","SettingMessage":"${true}"}`;

      this.pushMqttMessage(message);
    },
    closeTherapistToolsModal() {
      this.showTherapistTools = false;
    },

    giveStarForPatinent() {
      const message = `{"giveStarForPatient":"true"}`;
      console.log(message);
      this.pushMqttMessage(message);
    },
    remoteShareScreenController() {
      console.log(!this.remoteShareScreen);
      this.remoteShareScreen = !this.remoteShareScreen;
      const message = `{"remoteShareScreen":"${!this.remoteShareScreen}"}`;
      console.log(message);

      this.pushMqttMessage(message);
    },
    remoteVideoLocationController() {
      const message = `{"remoteVideoLocation":"${!this.remoteVideoLocation}"}`;
      console.log(message);
      this.pushMqttMessage(message);
    },
    remoteScreenSizeController() {
      const message = `{"remoteScreenSize":"${!this.remoteScreenSize}"}`;
      console.log(message);
      this.pushMqttMessage(message);
    },
    faceApiController() {

      if (this.remoteFaceApi) {
        this.remoteItems[3].icon = "fa-solid fa-heart-pulse text-danger text-danger";
        console.log(this.myItems);
        this.faceapi = null
        this.remoteFaceApi = false;
      } else {
        if (this.initFaceApi()) {
          this.remoteItems[3].icon = "fa-solid fa-heart-pulse text-danger text-primary";
          this.remoteFaceApi = true;
        }
        else {
          this.remoteFaceApi = false;
          this.$toast.add({
            severity: "warn",
            summary: "Hata",
            detail: "Duygu Durum Analizi Başlatılamadı",
            life: 2000,
          });
        }


      }



    },

    async startSeans() {
      this.mediaControllerModal = false;
      if (this.videoInputDeviceTestOld !== null)
        this.videoInputDeviceTestOld.stop();
      if (this.audioInputDeviceTestOld !== null)
        this.audioInputDeviceTestOld.stop();

      await this.startMeeting(
        this.selectedCameraDevice,
        this.selectedAudioInputDevice
      );
      this.screenSizeController();

      if (this.user.role === "patient") {
        const screenTrack = new LocalVideoTrack(this.stream.getTracks()[0], {
          name: "myscreenshare",
        });
        this.activeRoom.localParticipant.publishTrack(screenTrack);
        console.log(this.shareScreen);
        this.shareScreen = true;
        this.myItems[2].icon = "pi pi-external-link text-primary";
      }
    },
    mediaController() {
      if (this.mediaControllerModal) {
        if (this.videoInputDeviceTestOld !== null)
          this.videoInputDeviceTestOld.stop();
        if (this.audioInputDeviceTestOld !== null)
          this.audioInputDeviceTestOld.stop();
      }
      this.mediaControllerModal = !this.mediaControllerModal;
    },
    pushMqttMessage(message) {
      this.publish.payload = message;
      this.doPublish(this.client, message);
    },

    publishGame() {
      this.closeBoardRoom();
      let message = "";
      if (this.selectedGame.disease_type == "TwoPlayerGame") {
        message = `{"TwoPlayerGame":"${this.selectedGame.game_link}"}`;
      } else {
        message = `{"gameLink":"${this.selectedGame.game_link}"}`;
      }

      this.pushMqttMessage(message);
      this.publishStartGame();
    },

    publishStartGame() {
      const message = `{"startGame":"${true}"}`;
      this.pushMqttMessage(message);
    },
    publishPauseGame() {
      const message = `{"pauseGame":"${true}"}`;
      this.pushMqttMessage(message);
    },
    publishStopGame() {
      const message = `{"stopGame":"${true}"}`;
      this.pushMqttMessage(message);
    },
    publishQuesion() {
      const message = `{"questionId":"${this.selectedSheetQuestion.id}"}`;
      if (this.startGame) {
        this.publishPauseGame();
      }
      this.pushMqttMessage(message);
    },
    publishText() {
      const message = `{"word":"${this.therapistText}","appointment_id":"${this.appointment.id}","patient_id":"${this.appointment.patient_id}","isCustom":"true"}`;
      if (this.startGame) {
        this.publishPauseGame();
      }
      this.pushMqttMessage(message);
    },
    // BOARD DEĞİŞİKLİK

    openBoardRoom() {
      document.getElementById("gameFrame").src =
        "https://stream.dilvekonusmaterapim.com/demos/canvas-designer/index.html?roomId=" +
        this.appointment.id +
        "&dktdatetime=" +
        Date.now() +
        "&method=open";
      this.startBoard = true;

      const message = `{"openBoard":"${this.boardUrl}"}`;

      this.doPublish(this.client, message);
    },
    closeBoardRoom() {
      document.getElementById("gameFrame").src = "";
      this.startBoard = false;

      const message = `{"stopGame":"${true}"}`;

      this.doPublish(this.client, message);
    },
    createAnswer(isTrue) {
      this.answer.is_true = isTrue;
      this.answer.patient_id = this.appointment.patient_id;
      this.answer.appointment_id = this.appointment.id;

      this.answer.word = "";
      this.questions.forEach((question) => {
        this.answer.word += question.word + "&";
        this.answer.word_pic = question.word_pic;
        this.answer.sheet_name = question.sheet_name;
        this.answer.sheet_id = question.sheet_id;
        this.answer.sheet_question_id = question.id;
      });
    },
    resetQuesion() {
      const message = `{"resetQuestions":"true"}`;
      this.pushMqttMessage(message);
    },
    async applyAnswer() {
      const message = `{"questionAnswer":"true"}`;
      this.pushMqttMessage(message);
      this.answer = {};
      await this.createAnswer(true);
      await sheetAnswerService.setAnswer(this.answer);
    },
    async rejectAnswer() {
      const message = `{"questionAnswer":"false"}`;
      this.pushMqttMessage(message);
      this.answer = {};
      await this.createAnswer(false);
      await sheetAnswerService.setAnswer(this.answer);
    },

    async leaveMeet() {
      this.activeRoom.disconnect();
    },

    videoLocationController() {
      const remoteMediaDiv = document.querySelector("#remote_video_container");
      const remoteScreenMediaDiv = document.querySelector(
        "#remote_screen_video_container"
      );

      const remoteMediaVideoElement = remoteMediaDiv.querySelector("div");
      const remoteScreenVideoElement =
        remoteScreenMediaDiv.querySelector("div");

      if (remoteMediaVideoElement)
        remoteScreenMediaDiv.appendChild(remoteMediaVideoElement);
      if (remoteScreenVideoElement)
        remoteMediaDiv.appendChild(remoteScreenVideoElement);
    },
    screenSizeController() {
      var doc = window.document;
      var docEl = doc.documentElement;
      var requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen;
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl);
        this.screenFullSize = true;
        this.myItems[3].icon = "fa-solid fa-minimize text-primary";
      } else {
        cancelFullScreen.call(doc);
        this.screenFullSize = false;
        this.myItems[3].icon = "fa-solid fa-maximize text-danger";
      }
    },
    async microphoneController() {
      if (this.microphone) {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });
        this.myItems[1].icon = "fa-solid fa-microphone-slash text-danger";
        console.log(this.myItems);
        this.microphone = false;
      } else {
        this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
        });
        this.myItems[1].icon = "fa-solid fa-microphone text-primary";
        this.microphone = true;
      }

      this.answer = {};
    },
    async cameraController() {
      if (this.camera) {
        this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.disable();
          this.myItems[0].icon = "pi pi-camera text-danger";
        });
        this.camera = false;
      } else {
        this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
          publication.track.enable();
          this.myItems[0].icon = "pi pi-camera text-primary";
        });
        this.camera = true;
      }

      this.answer = {};
    },
    async shareScreenController() {
      console.log(this.shareScreen);
      console.log("  async shareScreenController() ");
      if (this.shareScreen) {
        this.activeRoom.localParticipant.videoTracks.forEach((element) => {
          console.log(element);
          console.log(element.trackName);
          if (element.trackName === "myscreenshare") {
            element.unpublish();
            this.stream.getTracks()[0].stop();
          }
        });
        this.myItems[2].icon = "pi pi-external-link text-danger";
        this.shareScreen = false;
      } else {
        this.stream = await navigator.mediaDevices.getDisplayMedia({
          video: { frameRate: 15 },
        });
        const screenTrack = new LocalVideoTrack(this.stream.getTracks()[0], {
          name: "myscreenshare",
        });
        this.activeRoom.localParticipant.publishTrack(screenTrack);
        this.myItems[2].icon = "pi pi-external-link text-primary";
        this.shareScreen = true;
      }
    },

    async getSheetQuestionBySheetId() {
      var tokenData = localStorage.getItem("user_token");

      if (tokenData) {
        this.sheetQuestion = this.allSheetQuestions.filter(
          (x) => x.sheet_id == this.selectedVoiceTherapistSheet.id
        );
      }
    },
    async getSheetQuestionBySheetIdFromApi() {
      var tokenData = localStorage.getItem("user_token");

      if (tokenData) {
        this.sheetQuestion = await sheetQuestionService.getBySheetId(
          this.selectedVoiceTherapistSheet.id
        );
      }
    },
  },
};
</script>

<style lang="scss">
:root {
  --question-background-color: white;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 3rem !important;
  height: 3rem !important;
}

.p-speeddial {
  display: inline !important;
}

.p-dropdown {
  width: 100%;
}

.p-inputtextarea {
  width: 100%;
}

.country-item {
  img {
    width: 25px;
    margin-right: 5px;
  }
}

.p-dropdown-panel {
  z-index: 9999 !important;
}

.patientQuestionCard {
  background: var(--question-background-color) !important;
}

.starCardBg {
  background: #202124 !important;
}

.p-dialog .p-dialog-header {
  padding: 0px !important;
}

#local-media>video {
  margin-top: 30px !important;
  width: 100% !important;
}

#remote-media-div>video {
  margin-top: 30px !important;
  width: 100% !important;
  z-index: 1;
}

#remote-media-div {
  max-height: 90vh !important;
  display: flex;
  justify-content: center;
}

#remote-screen-media-div {
  max-height: 90vh !important;
}

#remote-screen-media-div>video {
  margin-top: 30px !important;
  width: 100% !important;
}

.margin-zero {
  margin: 0px !important;
}

.meet-button {
  margin-top: 10px;
}

#meet-button-container {
  display: flex;
  justify-content: center;
  height: 10vh;
}

#meet-tools {
  display: none;
}

#meet-container {
  display: none;
}
</style>
